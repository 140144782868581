import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import SwiperBookComponents from "./SwiperBookComponents";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import "../css/GenreComponents.css";

const GenreComponents = ({ genres, books }) => {
  const [key, setKey] = useState("");
  const [booksByGenre, setBooksByGenre] = useState({});

  useEffect(() => {
    if (genres && books) {
      const booksByGenreObj = {};
      genres.forEach((genre) => {
        booksByGenreObj[genre] = books.filter((book) => book.genre === genre);
      });
      setBooksByGenre(booksByGenreObj);

      if (genres.length > 0) {
        setKey(genres[0]); // Set the first genre as the default key
      }
    }
  }, [genres, books]);

  const handleSelect = (k) => {
    setKey(k);
  };

  const currentBooks = Array.isArray(booksByGenre[key])
    ? booksByGenre[key]
    : [];

  return (
    <>
      <div className="genre-components-container">
        <Nav
          variant="tabs"
          activeKey={key}
          onSelect={handleSelect}
          className="genre-nav"
        >
          <Nav.Item className="genre-nav-items">
            {Object.keys(booksByGenre).map((genre) => (
              <Nav.Link key={genre} eventKey={genre}>
                {genre}
              </Nav.Link>
            ))}
          </Nav.Item>
          <div className="view-more-container">
            <Link to={`/genre-based-books/${key}`} className="view-more">
              View More <FontAwesomeIcon icon={faCaretRight} />
            </Link>
          </div>
        </Nav>
        <div className="genre-books-row">
          <SwiperBookComponents books={currentBooks} id="swiper1" />
        </div>
      </div>
    </>
  );
};

export default GenreComponents;
