import DisplayBook from "./DisplayBook";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import { useState, useEffect } from "react";

const SwiperBookComponents = ({ books, id }) => {
  const customNavStyle = {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 100,
    color: "blue",
    padding: "15px",
    borderRadius: "50%",
    cursor: "pointer",
  };

  return (
    <div style={{ position: "relative", width: "100%", padding: "0 10vw" }}>
      <Swiper
        navigation={{
          prevEl: `.${id}-swiper-button-prev`,
          nextEl: `.${id}-swiper-button-next`,
        }}
        modules={[Navigation, Autoplay]}
        className="mySwiper"
        // style={{ width: "100%" }}
        pagination={{ clickable: true }}
        breakpoints={{
          0: { slidesPerView: 2, spaceBetween: 1 },
          480: { slidesPerView: 2, spaceBetween: 1 },
          640: { slidesPerView: 2, spaceBetween: 1 },
          768: { slidesPerView: 2, spaceBetween: 1 },
          904: { slidesPerView: 2, spaceBetween: 5 },
          1024: { slidesPerView: 3, spaceBetween: 7 },
          1200: { slidesPerView: 4, spaceBetween: 8 },
          1440: { slidesPerView: 5, spaceBetween: 9 },
          1600: { slidesPerView: 6, spaceBetween: 10 },
          1920: { slidesPerView: 7, spaceBetween: 12 },
        }}
      >
        {books.map((book, index) => (
          <SwiperSlide key={index} style={{ width: "calc(100% / 6)" }}>
            <DisplayBook book={book} />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Custom navigation buttons */}
      <div
        className={`${id}-swiper-button-prev custom-prev`}
        style={{ ...customNavStyle, left: "10px" }}
      >
        &#10094;
      </div>
      <div
        className={`${id}-swiper-button-next custom-next`}
        style={{ ...customNavStyle, right: "10px" }}
      >
        &#10095;
      </div>
    </div>
  );
};

export default SwiperBookComponents;
