// src/App.jsx
import React, { useEffect, useState, useContext, useRef } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Home from "./Components/Home";
import ViewMorePage from "./Components/ViewMorePage";
import Contact from "./assets/Contact";
import { Cart } from "./Components/Cart";
import "./Scss/scssStyles.scss";
import GenreBasedBook from "./Components/GenreBasedBook";
import LoginPage from "./Components/login";
import SignupPage from "./Components/SignUp";
import config from "./Config/config";
import { Orders } from "./Components/Orders";
import TransactionTable from "./Components/TransactionTable";
import ReturnBooks from "./Components/ReturnBooks";
import axiosInstance, { setupAxiosInterceptors } from "./axiosInstance";
import TermsConditions from "./policy/TermsConditions";
import PrivacyPolicy from "./policy/PrivacyPolicy";
import RefundPolicy from "./policy/ RefundPolicy";
import ReturnPolicy from "./policy/ReturnPolicy";
import ShippingPolicy from "./policy/Shipping Policy";
import Error404 from "./ErrorPage/Error404";
import Error500 from "./ErrorPage/Error500";
import { ErrorContext } from "./Context/ErrorContext";
import AboutUs from "./assets/AboutUs";

import ForgetPassword from "./Components/ForgetPassword";
import Cookie from "./Components/Cookies";
import RequestBook from "./Components/RequsetBook";
import PaymentStatus from "./Components/PaymentStatus";
import ChatContainer from "./chatBot/ChatContainer";
import SEO from "./Components/SEO";
import Model from "./Components/Model";
import Details from "./Components/Details";

const App = () => {
  // State variables
  const [books, setBooks] = useState([]);
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [selectedAges, setSelectedAges] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [ageCategories, setAgeCategories] = useState([]);
  const [genres, setGenres] = useState([]);
  const [staffBooks, setStaffBooks] = useState([]);
  const [message, setMessage] = useState();
  const [randomValue, setRandomValue] = useState(
    Math.floor(Math.random() * 10000)
  );

  const location = useLocation();
  const { error, setError } = useContext(ErrorContext);

  const [isChatbotVisible, setIsChatbotVisible] = useState(false);

  const toggleChatbot = () => {
    setIsChatbotVisible(!isChatbotVisible);
  };
  useEffect(() => {
    setupAxiosInterceptors(setError);
  }, [setError]);

  useEffect(() => {
    const newRandomValue = Math.floor(Math.random() * 10000);
    setRandomValue(newRandomValue);
  }, [location]);

  const fetchBooksData = async () => {
    try {
      const response = await axiosInstance.get(
        `${config.booksUrl}?v=${randomValue}`
      );
      const data = response.data;
      const booksArray = Object.keys(data).map((key) => ({
        isbn: data[key].in,
        title: data[key].te,
        sellingPrice: data[key].sp,
        lendingPricePerDay: data[key].lp,
        author: data[key].au,
        ageGroup: data[key].ag,
        genre: data[key].cy,
        condition: data[key].cn,
        stockId: data[key].sids,
        weight: data[key].wt,
        mrp: data[key].mrp,
        imageUrl: `${config.imageUrlBase}${data[key].in}.jpg`,
      }));

      const ageCategoriesArray = [
        ...new Set(
          Object.keys(data)
            .map((key) => data[key].ag)
            .filter((age) => age !== null)
        ),
      ];
      setAgeCategories(ageCategoriesArray);

      const genreArray = [
        ...new Set(Object.keys(data).map((key) => data[key].cy)),
      ];
      setGenres(genreArray);

      setBooks(booksArray);
      setFilteredBooks(booksArray);
    } catch (error) {
      console.error("Error fetching the book data:", error);
      // 5xx errors are handled by Axios interceptor
    }
  };

  const fetchStaffPicksData = async () => {
    try {
      const response = await axiosInstance.get(
        `${config.staffPicksUrl}?v=${randomValue}`
      );
      const data = response.data;
      const staffBooksArray = Object.keys(data).map((key) => ({
        isbn: data[key].in,
        title: data[key].te,
        sellingPrice: data[key].sp,
        lendingPricePerDay: data[key].lp,
        author: data[key].au,
        ageGroup: data[key].ag,
        genre: data[key].cy,
        condition: data[key].cn,
        stockId: data[key].sids,
        weight: data[key].wt,
        mrp: data[key].mrp,
        imageUrl: `${config.imageUrlBase}${data[key].in}.jpg`,
      }));
      setStaffBooks(staffBooksArray);
    } catch (error) {
      console.error("Error fetching the staff picks data:", error);
      // 5xx errors handled by Axios interceptor
    }
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setSelectedAges([]);
      setSelectedGenres([]);
      setSelectedConditions([]);
      setSearchTerm("");
    }
  }, [location.pathname]);

  // Use useLocation at the top level of the component
  const { pathname } = location;
  useEffect(() => {
    // Call data-fetching functions based on the route
    if (
      pathname === "/" ||
      pathname.startsWith("/view-more/") ||
      pathname.startsWith("/genre-based-books/")
    ) {
      fetchBooksData();
      fetchStaffPicksData();
      // fetchBasedOnYourViewData();
    }
  }, [randomValue, pathname]);

  useEffect(() => {
    let updatedBooks = books;
    if (selectedAges.length > 0) {
      updatedBooks = updatedBooks.filter((book) =>
        selectedAges.includes(book.ageGroup)
      );
    }
    if (selectedGenres.length > 0) {
      updatedBooks = updatedBooks.filter((book) =>
        selectedGenres.includes(book.genre)
      );
    }
    if (selectedConditions.length > 0) {
      updatedBooks = updatedBooks.filter((book) =>
        selectedConditions.includes(book.condition)
      );
    }
    if (searchTerm.length > 2) {
      updatedBooks = updatedBooks.filter(
        (book) =>
          (book.title &&
            book.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (book.author &&
            book.author.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
    setFilteredBooks(updatedBooks);
  }, [selectedAges, selectedGenres, books, selectedConditions, searchTerm]);

  // If a 5xx error has occurred, redirect to the Error500 page
  if (error && error.type === "500") {
    return <Navigate to="/error500" replace />;
  }

  return (
    <>
      <Routes>
        <Route path="*" element={<SEO />} />
        <Route
          path="/"
          element={
            <Home
              books={filteredBooks}
              genres={genres}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              staffpicks={staffBooks}
            />
          }
        />
        <Route path="/cook" element={<Cookie />} />
        <Route
          path="/view-more/:book"
          element={
            <ViewMorePage
              books={filteredBooks}
              ageCategories={ageCategories}
              selectedAges={selectedAges}
              setSelectedAges={setSelectedAges}
              genres={genres}
              selectedGenres={selectedGenres}
              setSelectedGenres={setSelectedGenres}
              selectedConditions={selectedConditions}
              setSelectedConditions={setSelectedConditions}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              staffBooks={staffBooks}
            />
          }
        />
        <Route
          path="/genre-based-books/:genre"
          element={
            <GenreBasedBook
              books={filteredBooks}
              ageCategories={ageCategories}
              selectedAges={selectedAges}
              setSelectedAges={setSelectedAges}
              genres={genres}
              selectedGenres={selectedGenres}
              setSelectedGenres={setSelectedGenres}
              selectedConditions={selectedConditions}
              setSelectedConditions={setSelectedConditions}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="/aboutus"
          element={
            <AboutUs
              genres={genres}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="/contact"
          element={
            <Contact
              genres={genres}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="/cart"
          element={
            <Cart
              onSearch={setSearchTerm}
              searchTerm={searchTerm}
              books={filteredBooks}
            />
          }
        />
        <Route
          path="/request"
          element={
            <RequestBook
              genres={genres}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="/forgetPAssword"
          element={
            <ForgetPassword
              genres={genres}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="/payment_status"
          element={
            <PaymentStatus
              genres={genres}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route path="/book/:isbn" element={<Details books={books} />} />
        <Route path="/returnBook" element={<ReturnBooks />} />
        <Route path="/order" element={<Orders book={filteredBooks} />} />
        <Route path="/transaction" element={<TransactionTable />} />
        <Route
          path="/aboutus"
          element={
            <AboutUs
              genres={genres}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="/contact"
          element={
            <Contact
              genres={genres}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/signup"
          element={
            <SignupPage
              genres={genres}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="/terms_condtn"
          element={
            <TermsConditions
              genres={genres}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="/privacy_policy"
          element={
            <PrivacyPolicy
              genres={genres}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="/refund_policy"
          element={
            <RefundPolicy
              genres={genres}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="/return_policy"
          element={
            <ReturnPolicy
              genres={genres}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="/shipping_policy"
          element={<ShippingPolicy genres={genres} />}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <Route
          path="/aboutus"
          element={
            <AboutUs
              genres={genres}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="/contact"
          element={
            <Contact
              genres={genres}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        {/* Error Routes */}
        <Route path="/error500" element={<Error500 />} />{" "}
        {/* Add Error500 Route */}
        {/* Wildcard route for 404 Not Found */}
        <Route path="*" element={<Error404 />} />
      </Routes>
      {/* Chatbot Toggle Button */}
      <button
        onClick={toggleChatbot}
        style={{
          position: "fixed",
          bottom: "80px",
          right: "20px",
          backgroundColor: "#0078D7",
          color: "#fff",
          border: "none",
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          cursor: "pointer",
          fontSize: "24px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          zIndex: 9999,
        }}
      >
        💬
      </button>

      {/* Chatbot Container */}
      {isChatbotVisible && (
        <div
          style={{
            position: "fixed",
            bottom: "150px",
            right: "20px",
            border: "1px solid #ccc",
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            backgroundColor: "#fff",
            overflow: "hidden",
            zIndex: 1000,
          }}
        >
          {/* ChatContainer Component */}

          <ChatContainer
            onClose={() => setIsChatbotVisible(false)}
            books={books}
          />
        </div>
      )}
    </>
  );
};

export default App;
