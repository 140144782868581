import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "../css/ChatContainer.css";
import config from "../Config/config";
import Model from "../Components/Model";

const ChatContainer = ({ onClose, books }) => {
  const [messages, setMessages] = useState([
    {
      requester: "assistant",
      message: "Tell me what you love, and I’ll find the book you need!",
    },
  ]);
  const [userMessage, setUserMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [messageCount, setMessageCount] = useState(0);
  const [show, setshow] = useState(false);
  const chatBoxRef = useRef(null);
  const [book, setBook] = useState({});
  const [loading, setLoading] = useState(false);

  const handleShow = () => {
    setshow(!show);
  };
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, isLoading]);

  const extractBookInfo = (data) => {
    if (
      data.bookInfo &&
      Array.isArray(data.bookInfo) &&
      data.bookInfo.length > 0
    ) {
      return data.bookInfo.map((book) => ({
        isbn: book.isbn,
        title: book.title,
        author: book.author,
      }));
    }
    return [];
  };

  const sendMessage = async () => {
    if (!userMessage.trim()) return;
    setUserMessage("");
    const updatedMessages = [];
    for (let i = messageCount; i < messages.length; i++) {
      if (messages[i].message !== "Oops! Something went wrong.") {
        updatedMessages.push(messages[i]);
      }
    }
    updatedMessages.push({ requester: "user", message: userMessage });
    setMessages((pre) => [...pre, { requester: "user", message: userMessage }]);
    setIsLoading(true);

    try {
      const response = await axios.post(`${config.api.aibot}`, {
        message: updatedMessages,
      });

      const botMessage = response.data.followupQuestion;
      const session =
        response.data.sessionEnd === "true" ? response.data.sessionEnd : false;

      const bookInfo = extractBookInfo(response.data);
      const newMessages = [{ requester: "assistant", message: botMessage }];
      if (bookInfo.length > 0) {
        const bookInfoMessage = bookInfo
          .map(
            (book) =>
              `ISBN: ${book.isbn}\nTitle: ${book.title}\nAuthor: ${book.author}`
          )
          .join("\n\n");
        newMessages.push({ requester: "assistant", message: bookInfoMessage });
      }

      setMessages((prev) => [...prev, ...newMessages]);

      setCount((pre) => {
        const newCount = pre + 2;
        if (session) {
          setMessageCount(newCount + 1);
        }
        return newCount;
      });
    } catch (error) {
      console.log(error.response);
      setMessages((prev) => [
        ...prev,
        { requester: "assistant", message: "Oops! Something went wrong." },
      ]);
    } finally {
      setIsLoading(false);
    }

    setUserMessage("");
  };

  const getDetails = async (isbn) => {
    setLoading(!loading);
    const selectedBook = await books.find((item) => item.isbn === isbn);
    if (selectedBook) {
      setBook(selectedBook);
      setshow(!show);
    }
    setLoading(!loading);
  };

  const extractISBNFromMessage = (message) => {
    const isbnRegex = /\b\d{3}[-]?\d{1,5}[-]?\d{1,7}[-]?\d{1,7}[-]?\d{1,7}\b/g;
    const match = message.match(isbnRegex);
    return match ? match : [];
  };
  return (
    <>
      <div className="chat-container">
        <button className="close-button" onClick={onClose}>
          ✕
        </button>
        <div className="chat-box" ref={chatBoxRef}>
          {messages.map((msg, index) => {
            const isbnList = extractISBNFromMessage(msg.message);
            return (
              <div
                key={index}
                className={`message ${
                  msg.requester === "user" ? "user-message" : "bot-message"
                }`}
              >
                <div className="message-text">
                  {msg.message.includes("\n")
                    ? msg.message.split("\n").map((line, idx) => {
                        const index = Math.floor(idx / 4);
                        return (
                          <React.Fragment key={idx}>
                            <button
                              style={{
                                all: "unset",
                                cursor: "pointer",
                                textDecoration:
                                  idx % 4 === 0 ? "underline" : "none",
                              }}
                              onClick={() => getDetails(isbnList[index])}
                            >
                              {line}
                            </button>
                            <br />
                          </React.Fragment>
                        );
                      })
                    : msg.message}
                </div>
              </div>
            );
          })}
          {isLoading && (
            <div className="loading">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          )}
        </div>

        <div className="input-container">
          <input
            className="input"
            type="text"
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendMessage();
              }
            }}
            placeholder="Type your message..."
          />
          <button className="button" onClick={sendMessage}>
            Send
          </button>
        </div>
      </div>
      {show && <Model book={book} show={show} handleShow={handleShow} />}
    </>
  );
};

export default ChatContainer;
