import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import FilterComponent from "../Components/FilterComponent";
import { Link, useLocation } from "react-router-dom";
import "../css/SideBarMenuTwo.css";

const SideBarMenuTwo = ({
  genres,
  ageCategories,
  selectedAges,
  onAgeSelect,
  selectedGenres,
  onGenreSelect,
  selectedConditions,
  onConditionSelect,
  setFilteredBooks,
  filteredBooks,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [viewButton, setViewButton] = useState(false);

  useEffect(() => {
    const update = () => {
      if (window.innerWidth <= 1480) {
        setViewButton(true);
        setIsFilterOpen(false);
      } else {
        setViewButton(false);
        setIsFilterOpen(true);
      }
    };
    update();

    window.addEventListener("resize", update);
    return () => window.removeEventListener("resize", update);
  }, []);

  const handleAllBooksClick = () => {
    onGenreSelect([]);
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const location = useLocation();

  return (
    <div style={{ display: "flex" }}>
      <Navbar expand="lg" className="navbar-custom">
        <Container fluid style={{ backgroundColor: "#3c3c3c", padding: 0 }}>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={{ background: "white" }}
          />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="navbar-collapse-custom"
          >
            <Nav
              className="me-auto"
              style={{ backgroundColor: "#3c3c3c", width: "100%" }}
            >
              <Nav.Item className="nav-item-first"></Nav.Item>
              <Nav.Item className="nav-item-spacing">
                <Link
                  to={`/view-more/book`}
                  style={{ color: "#fff", textDecoration: "none" }}
                  onClick={handleAllBooksClick}
                >
                  AllBooks
                </Link>
              </Nav.Item>
              {genres.map((genre) => (
                <Nav.Item key={genre} className="nav-item-spacing">
                  <Link
                    to={`/genre-based-books/${genre}`}
                    style={{ color: "#fff", textDecoration: "none" }}
                  >
                    {genre}
                  </Link>
                </Nav.Item>
              ))}
            </Nav>
            {location.pathname !== "/request" && (
              <Nav.Item className="nav-item-spacing">
                <Link to="/request" className="nav-link-custom">
                  RequestBook
                </Link>
              </Nav.Item>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div>
        {isFilterOpen && (
          <div className="sideBar">
            <FilterComponent
              genres={genres}
              ageCategories={ageCategories}
              selectedAges={selectedAges}
              onAgeSelect={onAgeSelect}
              selectedGenres={selectedGenres}
              onGenreSelect={onGenreSelect}
              selectedConditions={selectedConditions}
              onConditionSelect={onConditionSelect}
              setFilteredBooks={setFilteredBooks}
              filteredBooks={filteredBooks}
            />
          </div>
        )}
        {viewButton && (
          <Button
            variant="primary"
            className="filter-button"
            onClick={toggleFilter}
          >
            {isFilterOpen ? "Close Filter" : "Filter"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SideBarMenuTwo;
