import React, { useState, useEffect } from "react";
import DisplayBook from "./DisplayBook";
import "../css/DisplayBooksGrid.css";

const DisplayBooksGrid = ({ books }) => {
  const [columns, setColumns] = useState(6);

  useEffect(() => {
    const updateColumns = () => {
      if (window.innerWidth <= 480) {
        setColumns(2);
      } else if (window.innerWidth <= 1020) {
        setColumns(3);
      } else if (window.innerWidth <= 1600) {
        setColumns(4);
      } else {
        setColumns(6);
      }
    };

    updateColumns();
    window.addEventListener("resize", updateColumns);
    return () => window.removeEventListener("resize", updateColumns);
  }, []);

  return (
    <div className="grid-container">
      <div
        className="book-grid"
        style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
      >
        {books.map((book) => (
          <DisplayBook key={book.isbn} book={book} />
        ))}
      </div>
    </div>
  );
};

export default DisplayBooksGrid;
