import React, { useState } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BookInfo from "./BookInfo";
import Price from "./Price";
import "react-lazy-load-image-component/src/effects/blur.css";
import "../css/DisplayBook.css";
const DisplayBook = ({ book }) => {
  const [isImgError, setIsImgError] = useState(false);
  const handleError = () => setIsImgError(true);

  return (
    <Card
      style={{
        width: "100%",
        maxWidth: "14rem",
        margin: "10px",
        border: "none",
        transition: "0.3s",
        position: "relative",
      }}
    >
      <Link to={`/book/${book.isbn}`} style={{ textDecoration: "none" }}>
        <div
          style={{
            width: "150px",
            height: "200px",
            margin: "0 auto",
            overflow: "hidden",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: isImgError ? "#f0f0f0" : "transparent",
            cursor: "pointer",
          }}
        >
          {!isImgError ? (
            <LazyLoadImage
              src={book.imageUrl}
              alt={book.title}
              effect="blur"
              onError={handleError}
              className="custom-image"
            />
          ) : (
            <span className="custom-error-text">{book.title}</span>
          )}
          <Badge className="custom-badge">{book.condition}</Badge>
        </div>
      </Link>

      <Card.Body style={{ padding: "10px", textAlign: "center" }}>
        <Card.Title
          style={{
            fontSize: "0.9rem",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {book.title}
        </Card.Title>
        <BookInfo author={book.author} />
        <Price
          lendingPricePerDay={book.lendingPricePerDay}
          sellingPrice={book.sellingPrice}
          book={book}
        />
      </Card.Body>
    </Card>
  );
};

export default DisplayBook;
